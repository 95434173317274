<template>
  <div class="banner">
    <picture>
      <source media="(min-width: 582px)" :srcset="require('@/assets/' + banner)">
      <source  :srcset="require('@/assets/' + mobileBanner)">
      <img :src="require('@/assets/' + banner)" alt="Parts Catalogue Header">
    </picture>

    <div class="banner__content"> 
      <h2 class="banner__heading uppercase"> {{heading}}</h2>
      <h3 class="banner__subheading"> {{subheading}} </h3>
    </div>
  </div>
</template>

<script>
//View the full range of Genuine BMW &amp; MINI Parts
export default {
    name: "Banner",
  props: {
      banner: {
          type: String,
          required: true
      },
      mobileBanner: {
          type: String,
          reqiored:true
      },
      heading: {
           type: String,
          reqiored:true
      },
      subheading: {
           type: String,
          reqiored:true
      }
  }
};

</script>

<style scoped>

</style>

