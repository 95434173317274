<template>
    <div>
        <Banner banner="banner-x3-x4-desktop.jpg" mobileBanner="parts-catalogue-header-mobile.jpg" heading="Parts Catalogue" subheading="View the full range of Genuine BMW &amp; MINI Parts" />
        <div class="parts">
            <h2>Genuine BMW & Mini Brakes</h2>
            <PartsList @updateItemsCount="updateItemsCount" />
        </div>
    </div>
</template>

<script>

    import Banner from "@/components/banner.vue"
    import PartsList from '@/components/parts-list.vue';

    export default {
        name: "Parts",
        components: {
            PartsList,
            Banner
        },
        methods: {
            updateItemsCount(count) {
                this.$emit('updateItemsCount', count);
            }
        }
    };
</script>

<style scoped>
    .parts {
        margin: 40px;
    }

    h2 {
        text-align: left;
    }
</style>