<template>
  <div class="category-panel">
    <ImageDefault v-bind:imageBytes='category.imageBytes'></ImageDefault>
    <h3>{{ category.name }}</h3>
    <button class="button button--mt" @click="$router.push('parts/' + category.name)">View all parts</button>    
  </div>
</template>

<script>
import  ImageDefault  from "@/components/image-default.vue";

export default {
  name: "CategoryPanel",
  components: {
      ImageDefault
  },
  props: {
      category: {
          type: Object,
          required: true
      }
  }
};

</script>

<style scoped>
h3 {
    margin: 5px;
    text-transform: uppercase;

}
.category-panel {
    margin: 5px;
    padding: 5px;
    border: 1px;
    border-color: #e6e6e6;
    border-style: solid;
    box-shadow: 1px 1px 2px 1px #e6e6e6;
}
</style>

