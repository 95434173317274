<template>
  <img :src="creatorImage"/>
</template>

<script>
  export default {
  name: "CategoryPanel",
  props: {
        imageBytes: String
  },
  data() {
  return {
      imageError: false,
      defaultImage: require("@/assets/default.jpg")
  };
  },
  computed: {
      creatorImage() {
          return this.imageBytes ? 'data:image/jpeg;base64,/' + this.imageBytes : this.defaultImage ;
      }
    }
  }
</script>
<style scoped>


img {
  width: 405px;
}
</style>
