<template>
    <div class="header" id="header">
        <div class="header__inner">
            <div class="header__nav">
                <nav class="main-nav" id="main-nav">
                    <div class="main-nav__inner">
                        <ul>
                            <li><router-link tag="a" to="/">Home</router-link></li>
                            <li><router-link tag="a" to="/about">About</router-link></li>
                            <li>
                                <router-link tag="a" to="/cart">Cart</router-link>
                                <span v-if="cartItemsCount" class="cart-items-count-tag">{{cartItemsCount}}</span>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <button id="nav-toggle" class="nav-toggle" type="button" data-toggle="main-nav">
                <span></span>
                <span></span>
                <span></span>
            </button>

            <div class="header__logo">
                <a href="/">
                    <img src="@/assets/logos.png" width="116" alt="BMW &amp; MINI Logos" id="logo">
                </a>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "NavHeader",
        props: {
            cartItemsCount: Number
        }
    };
</script>

<style scoped>
    #header-nav ul {
        list-style-type: none;
        padding: 0;
    }

    #header-nav li {
        display: inline-block;
        margin: 0 10px;
    }

    .cart-items-count-tag {
        background-color: #1C69D4;
        border-radius: 30px;
        padding: 3px 6px;
        color: whitesmoke;
        font-size: 12px;
        top: -6px;
        left: -8px;
        position: relative;
        border: 1px solid white;
        font-weight: bold;
    }
</style>
