import axios from "axios";

export default class APIClient {
  constructor(baseURL = process.env.VUE_APP_API_URL) {
    this.axiosConfig = {
      baseURL,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-type": "application/json"
      }
    };

    this.client = axios.create(this.axiosConfig);
  }
}
