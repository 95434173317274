<template>
  <div>
    <header class="header">
      <NavHeader :cartItemsCount="cartItemsCount"></NavHeader>
    </header>
  </div>
</template>

<script>
import NavHeader from "@/components/nav/nav-header.vue";

export default {
  name: "Header",
        components: { NavHeader },
        props: {
            cartItemsCount: Number
        }
};
</script>
