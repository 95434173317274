<template>
    <div>
        <Banner banner="banner-x3-x4-desktop.jpg" mobileBanner="parts-catalogue-header-mobile.jpg" heading="Parts Catalogue" subheading="View the full range of Genuine BMW &amp; MINI Parts" />
        <div class="cart-container">
            <div class="cart-details">
                <h2>Your cart details</h2>
                <p>Click on the part category to find out more. When you submit an order, the parts will be delivered to your preferred Dealer and you can just simply click and collect.</p>
                <table>
                    <thead>
                        <tr>
                            <th>Product information</th>
                            <th>Unit price</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.number">
                            <td>
                                {{item.description}}<br />
                                <span class="item-number">{{item.number}}</span>
                            </td>
                            <td>${{parseFloat(item.tradePrice).toFixed(2)}}</td>
                            <td class="quantity-cell">
                                <i class="fas fa-minus" v-on:click="decrementQuantity(item.number)"></i>
                                <span>{{item.quantity}}</span>
                                <i class="fas fa-plus" v-on:click="incrementQuantity(item.number)"></i>
                            </td>
                            <td>${{parseFloat(item.tradePrice * item.quantity).toFixed(2)}}</td>
                            <td class="delete-item-cell"><i v-on:click="removeItem(item.number)" class="far fa-trash-alt"></i></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3">Grand total</td>
                            <td colspan="2">
                                ${{parseFloat(total()).toFixed(2)}} <br />
                                <span class="vat">Including VAT</span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="cart-delivery">
                <h2>Delivery</h2>
                <p>By default, your preferred Dealer is preselected. If you want to use a different Dealer, please use the dropdown below.</p>

                <div class="dealer-selection-container">
                    <label>Choose Dealer</label>
                    <Dropdown v-model="selectedDealer" :options="dealers" optionLabel="name" placeholder="Select a dealer" />
                </div>

                <div class="dealer-details" v-if="selectedDealer">
                    <div class="dealer-details-container">
                        <div class="dealer-details-label-container">
                            <span>Dealer details</span>
                        </div>
                        <div class="dealer-details-values-container">
                            <div class="dealer-detail-value">
                                <i class="fas fa-map-marker-alt"></i>
                                <div>{{selectedDealer.address}}</div>
                            </div>
                            <div class="dealer-detail-value dealer-detail-value-no-icon">{{selectedDealer.postcode}} </div>
                            <div class="dealer-detail-value">
                                <i class="fas fa-phone-alt"></i>
                                <div>{{selectedDealer.telephone}}</div>
                            </div>
                            <div class="dealer-detail-value">
                                <i class="far fa-star"></i>
                                <div>{{selectedDealer.email}}</div>
                            </div>
                            <div class="dealer-detail-value">
                                <i class="far fa-clock"></i>
                                <div>{{selectedDealer.openHours}}</div>
                            </div>
                            <div class="dealer-detail-value">
                                <i class="fas fa-exclamation-circle"></i>
                                <div><a href="#">Get directions</a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <button v-on:click="onSubmit()" :disabled='isSubmitting || items.length === 0' v-bind:class="{ disabled: isSubmitting || items.length === 0 }" class="submit-btn">Submit order</button>
            </div>
        </div>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
    import Banner from "@/components/banner.vue"
    import { cartClient } from "../clients/cart-client";
    import { partsClient } from "../clients/parts-client";
    import { dealersClient } from "../clients/dealer-client";

    export default {
        name: "Cart",
        components: {
            Banner
        },
        data() {
            return {
                items: [],
                dealers: [],
                selectedDealer: null,
                total: () => this.items.map(x => x.quantity * x.tradePrice).reduce((a, b) => a + b, 0),
                isSubmitting: false
            }
        },
        methods: {
            async getCartItems() {
                let cart = cartClient.getCart();
                let cartItems = [];
                for (let i = 0; i < cart.length; i++) {
                    let item = cart[i];
                    let part = await partsClient.getPart(item.number);
                    let cartItem = { description: part.description, number: item.number, quantity: item.quantity, tradePrice: part.tradePrice };
                    cartItems.push(cartItem);
                }

                this.items = cartItems;
            },
            decrementQuantity(number) {
                let item = this.items.find(x => x.number === number);
                if (item.quantity > 0) {
                    item.quantity -= 1;
                    cartClient.decrementQuantity(number);
                }
                this.$emit('updateItemsCount', cartClient.getItemsCount());
            },
            incrementQuantity(number) {
                let item = this.items.find(x => x.number === number);
                item.quantity += 1;
                cartClient.incrementQuantity(number);
                this.$emit('updateItemsCount', cartClient.getItemsCount());
            },
            removeItem(number) {
                cartClient.removeFromCart(number);
                let item = this.items.find(x => x.number === number);
                if (item) {
                    this.items.splice(this.items.indexOf(item), 1);
                    this.$emit('updateItemsCount', cartClient.getItemsCount());
                    this.$toast.add({ severity: 'info', summary: 'Item has been removed', life: 3000 });
                }

            },
            async getDealers() {
                let dealers = await dealersClient.getDealers();
                this.dealers = dealers;
            },
            selectPrefferedDealer() {
                let dealerCode = localStorage.getItem('prefferedDealerCode');
                this.selectedDealer = this.dealers.find(x => x.code === dealerCode);
            },
            onSubmit() {

                this.$confirm.require({
                    message: 'Are you sure you want to proceed?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.submitCart();
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            submitCart() {
                this.isSubmitting = true;
                cartClient.submit(this.selectedDealer.code)
                    .then(() => {
                        cartClient.clearCart();
                        this.items = [];
                        this.isSubmitting = false;
                        this.$toast.add({ severity: 'success', summary: 'Your order has been submitted.', life: 3000 });
                        this.$emit('updateItemsCount', cartClient.getItemsCount());
                    })
                    .catch((e) => {
                        console.log(e);
                        this.$toast.add({ severity: 'error', summary: 'Cannot sumbit order.', detail: 'An error occurred while submitting the order.', life: 3000 });
                        this.isSubmitting = false;
                    });
            }
        },
        created() {
            localStorage.setItem('prefferedDealerCode', '1233'); //TODO: remove when prefferedDealerCode is set from the jwt
        },
        async mounted() {
            await this.getCartItems();
            await this.getDealers();
            this.selectPrefferedDealer();
        }
    };
</script>

<style scoped>
    .cart-container {
        margin: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 20px;
    }

    .cart-details, .cart-delivery {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        padding: 20px;
    }

    .cart-details {
        flex: 2;
    }

    .cart-delivery {
    }

    .dealer-details-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }

    .dealer-details-label-container, .dealer-details-values-container {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        padding: 10px 5px;
    }

    .dealer-details-label-container {
        text-align: right;
        padding-top: 18px;
    }

    .dealer-details-values-container {
        flex: 2;
        text-align: left;
        padding-left: 15px;
    }

    .dealer-detail-value {
        padding: 8px 0;
    }

        .dealer-detail-value i {
            float: left;
            margin-right: 4px;
        }

    .dealer-detail-value-no-icon {
        margin-left: 15px;
        padding-top: 0;
    }

    .dealer-selection-container {
        margin-top: 20px;
    }

        .dealer-selection-container label {
            text-align: left;
            float: left;
        }

        .dealer-selection-container .p-dropdown {
            margin-top: 8px;
            width: 100%;
            border: 1px solid #E4E4E4;
            border-radius: 0;
        }

    h2, p {
        text-align: left;
        margin: 5px;
    }

    h2 {
        text-transform: uppercase;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 30px;
    }

        table thead tr th {
            padding: 20px;
            background-color: #1C69D4;
            color: whitesmoke;
            text-align: left;
        }

    tbody tr, tfoot tr {
        border-right: solid 1px #E4E4E4;
        border-left: solid 1px #E4E4E4;
        border-bottom: solid 1px #E4E4E4;
    }

        tbody tr td, tfoot tr td {
            text-align: left;
            padding: 20px;
        }

        tfoot tr td {
            font-weight: bold;
        }

            tfoot tr td:first-child {
                text-align: right;
            }

    .quantity-cell i {
        margin: 0 5px;
        cursor: pointer;
        color: #878787;
    }

    .quantity-cell span {
        background-color: #eeeeee;
        padding: 3px 5px;
    }

    .delete-item-cell {
        cursor: pointer;
    }

    .item-number {
        color: #878787;
        font-size: smaller;
        font-weight: normal;
    }

    .vat {
        color: #878787;
        font-size: smaller;
        font-weight: normal;
    }

    .submit-btn {
        background-color: #1C69D4;
        color: whitesmoke;
        border: none;
        width: 200px;
        margin-top: 30px;
        padding: 20px 30px;
        font-weight: bold;
        font-size: large;
        cursor: pointer;
    }

    .disabled {
        background-color: #5e91d8;
        cursor: not-allowed;
    }
</style>