import ApiClient from "@/clients/api-client.js";

export default class DealersClient extends ApiClient {
    constructor() {
        super();
    }

    async getDealers() {
        try {
            let response = await this.client.get("/dealers");
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
}

export const dealersClient = new DealersClient();
