<template>
  <div class="hello">
    <Banner banner="banner-x3-x4-desktop.jpg" mobileBanner="parts-catalogue-header-mobile.jpg" heading="Parts Catalogue" subheading="View the full range of Genuine BMW &amp; MINI Parts"/>
    <div class = "subheader-content">
      <h2 class="uppercase">Genuine BMW &amp; Mini Parts</h2>
      <p>Click on the part category below to find out more. When you submit an order the parts will be delivered to your preferred Dealer and you can just simply click and collect.</p>
    </div>
    <div class="categories">
      <CategoryPanel
        v-for="partCategory in partCategories"
        v-bind:key="partCategory.id"
        v-bind:category="partCategory"
      />
    </div>
  </div>
</template>
<script>

import { partsClient } from "@/clients/parts-client.js";
import Banner from "@/components/banner.vue"
import  CategoryPanel  from "@/components/category-panel.vue";

export default {
  name: "PartCategories",
  
  components: { CategoryPanel, Banner },

  data() {
    return {
      partCategories: [] // The API returns part categories
    };
  },

  methods: {
    async getPartCategories() {
      const partCategoriesResponse = await partsClient.getPartsCategories();

      if (!partCategoriesResponse) {
        //TODO: Remove console log statements and replace once app-wide logging strategy is determined.
        console.warn("Unable to retrieve parts categories");
        return;
      }
      this.partCategories = partCategoriesResponse;
    }
  },
  async created() {
    await this.getPartCategories();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.subheader-content{
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.uppercase {
  text-transform: uppercase;
}
</style>
