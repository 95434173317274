<template>
    <div>
        <Toast />
        <Header :cartItemsCount="cartItemsCount"></Header>
        <router-view @updateItemsCount="updateItemsCount"></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from "@/components/header.vue";
    import Footer from "@/components/footer.vue";
    import { cartClient } from "../../clients/cart-client";

    export default {
        name: "AppLayout",
        components: { Header, Footer },
        data() {
            return {
                cartItemsCount: 0
            };
        },
        methods: {
            updateItemsCount(count) {
                this.cartItemsCount = count;
            }
        },
        mounted() {
            this.cartItemsCount = cartClient.getItemsCount();
        }
    };
</script>
