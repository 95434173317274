import Vue from "vue";
import VueRouter from "vue-router";
import AppLayout from "@/views/_layout/App-layout.vue";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Parts from "@/views/Parts.vue";
import Cart from "@/views/Cart.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: AppLayout,
        redirect: { name: "home" },
        children: [
            {
                path: "home",
                name: "home",
                component: Home
            },
            {
                path: "about",
                name: "about",
                component: About
            },
            {
                name: "parts",
                path: "parts/:categoryId",
                component: Parts
            },
            {
                name: "cart",
                path: "cart",
                component: Cart
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
