<template>
  <div id="footer">
    <NavBottom></NavBottom>
    <p>
      @ BMW Group Australia 2021 All rights reserved <br />
      <span class="poweredby">
        <a href="https://oeconnection.com/" target="_blank"
          >Powered by <img src="@/assets/oec-logo.png" alt="OEC"
        /></a>
      </span>
    </p>
    <NavFooter></NavFooter>
  </div>
</template>

<script>
import NavFooter from "@/components/nav/nav-footer.vue";
import NavBottom from "@/components/nav/nav-bottom.vue";

export default {
  name: "Footer",
  components: {
    NavFooter,
    NavBottom
  }
};
</script>

<style scoped>
#footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#footer p {
  text-align: left;
  margin-left: 1%;
}

.poweredby img {
  max-height: 12px;
  margin-left: 5px;
  display: inline-block;
}
.poweredby a {
  text-decoration: none;
  color: #262626;
}

#footer ul {
  margin: 5%;
}
</style>
