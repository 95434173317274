import ApiClient from "@/clients/api-client.js";
import { partsClient } from "../clients/parts-client";

export default class CartClient extends ApiClient {
    constructor() {
        super();
    }

    addToCart(number) {

        let cart = this.getCartFromStorage();
        let item = cart.find(x => x.number === number);
        if (item) {
            item.quantity += 1;
        } else {
            item = { number, quantity: 1 };
            cart.push(item);
        }

        this.saveCartInStorage(cart);
    }

    removeFromCart(number) {
        let cart = this.getCartFromStorage();
        let item = cart.find(x => x.number === number);
        if (item) {
            cart.splice(cart.indexOf(item), 1);
            this.saveCartInStorage(cart);
        }
    }

    getCartFromStorage() {
        let cartStr = window.localStorage.getItem("cart");
        let cart = [];
        if (cartStr) {
            cart = JSON.parse(cartStr);
        }

        return cart;
    }

    saveCartInStorage(cart) {
        window.localStorage.setItem('cart', JSON.stringify(cart));
    }

    getCart() {
        return this.getCartFromStorage();
    }

    decrementQuantity(number) {
        let cart = this.getCartFromStorage();
        let item = cart.find(x => x.number === number);

        if (item.quantity > 0) {
            item.quantity -= 1;
            if (item.quantity === 0) {
                cart.splice(cart.indexOf(item), 1);
            }
        }

        this.saveCartInStorage(cart);
    }

    incrementQuantity(number) {
        let cart = this.getCartFromStorage();
        let item = cart.find(x => x.number === number);

        if (!item) {
            this.addToCart(number);
        } else {
            item.quantity += 1;
            this.saveCartInStorage(cart);
        }
    }

    getItemsCount() {
        return this.getCartFromStorage().length;
    }

    async submit(dealerCode) {
        let cart = this.getCartFromStorage();
        let body = {
            cartItems: await Promise.all(cart.map(this.toCartItem)),
            dealerCode
        };

        return await this.client.post('/orders', body);
    }

    async toCartItem(x) {
        return {
            partNumber: x.number,
            quantity: x.quantity,
            price: (await partsClient.getPart(x.number)).tradePrice
        };
    }

    clearCart() {
        window.localStorage.removeItem('cart');
    }
}

export const cartClient = new CartClient();
