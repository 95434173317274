<template>
    <div>
        <div>
            <DataTable :value="parts" responsiveLayout="scroll" :paginator="true" :rows="10"
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       :rowsPerPageOptions="[10,20,50]"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                       dataKey="number" :filters="filters" :loading="loading">
                <template #header>
                    <div class="table-header">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global']" placeholder="Search" />
                        </span>
                    </div>
                </template>
                <template #empty>
                    No parts found.
                </template>
                <template #loading>
                    Loading parts. Please wait.
                </template>
                <Column field="description" header="Description" sortable></Column>
                <Column field="number" header="Part number" sortable></Column>
                <Column field="listPrice" header="Price" sortable>
                    <template #body="slotProps">
                        ${{slotProps.data.listPrice}}
                    </template>
                </Column>
                <Column field="tradePrice" header="Trade Price" sortable>
                    <template #body="slotProps">
                        ${{slotProps.data.tradePrice}}
                    </template>
                </Column>
                <Column field="details" header="Details"></Column>
                <Column field="actions" header="">
                    <template #body="slotProps">
                        <button v-on:click="addToCard(slotProps.data.number)"><i class="fas fa-shopping-cart"></i> Add to cart</button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>

    import { partsClient } from "@/clients/parts-client.js";
    import { cartClient } from "@/clients/cart-client.js";

    export default {
        name: "PartsList",
        data() {
            return {
                filters: {},
                parts: [],
                loading: false
            }
        },
        methods: {
            async getParts() {
                this.loading = true;
                let category = this.$route.params.categoryId;
                this.parts = await partsClient.getParts(category);
                this.loading = false;
            },
            addToCard(number) {
                cartClient.addToCart(number);
                this.$emit('updateItemsCount', cartClient.getItemsCount());
                this.$toast.add({ severity: 'success', summary: 'Item has been added', life: 3000 });
            }
        },
        async mounted() {
            await this.getParts();
        }
    };
</script>

<style scoped>

    /deep/ .p-datatable {
        width: 100%;
        border-collapse: collapse;
    }

        /deep/ .p-datatable .p-datatable-thead > tr > th {
            background-color: #1C69D4;
            color: whitesmoke;
            padding: 20px 10px;
            text-align: left;
        }

    /deep/ p-datatable-tbody tr {
        border-right: solid 1px #E4E4E4;
        border-left: solid 1px #E4E4E4;
        border-bottom: solid 1px #E4E4E4;
    }

        /deep/ p-datatable-tbody tr td {
            padding: 15px 10px;
            text-align: left;
        }

            /deep/ p-datatable-tbody tr td:last-child {
                text-align: center;
            }

    /deep/ button {
        color: whitesmoke;
        background-color: #1C69D4;
        padding: 5px 20px;
        text-decoration: none;
        border: none;
        cursor: pointer;
    }

        /deep/ button i {
            margin-right: 5px;
        }

    /deep/ .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
        color: whitesmoke;
    }

    /deep/ .p-datatable .p-sortable-column .p-sortable-column-icon {
        color: whitesmoke;
        margin-left: 0.5rem;
    }

    /deep/ .pi-sort-alt:before {
        content: "\e99e";
        color: white;
    }

    /deep/ .p-datatable .p-sortable-column:not(.p-highlight):hover,
    /deep/ .p-datatable .p-sortable-column.p-highlight:hover {
        background: #1C69D4;
        color: white;
    }

    /deep/ .p-datatable .p-datatable-header {
        background: #f8f9fa;
        color: #495057;
        border: 1px solid #e9ecef;
        border-width: 1px 0 1px 0;
        padding: 5px;
        font-weight: 600;
        margin-right: 0px;
    }

    /deep/ .p-datatable-header {
        display: flex;
        justify-content: space-between;
    }
</style>
