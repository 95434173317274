<template>
  <div id="footer-nav">
    <ul>
      <li><a v-bind:href="urlMyAccount">My Account</a></li>
      <li>
        <a v-bind:href="urlPrivacyPolicy" target="_blank">Privacy Policy</a>
      </li>
      <li>
        <a v-bind:href="urlTermsAndConditions" target="_blank"
          >Terms & Conditions</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavFooter",
  data() {
    return {
      urlMyAccount: process.env.VUE_APP_BMWTC_URL + "/my-account",
      urlPrivacyPolicy: process.env.VUE_APP_PRIVACY_URL,
      urlTermsAndConditions: process.env.VUE_APP_TERMS_URL
    };
  }
};
</script>

<style scoped>
#footer-nav ul {
  list-style-type: none;
  padding: 0;
}
#footer-nav li {
  display: inline-block;
  margin: 0 10px;
}

#footer-nav a {
  text-decoration: none;
  color: #262626;
}
</style>
