<template>
  <div class="home">
    <PartCategories />
  </div>
</template>

<script>
// @ is an alias to /src
import PartCategories from "@/components/part-categories.vue";
export default {
  name: "Home",
  components: {
    PartCategories
  }
};
</script>
