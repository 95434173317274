<template>
  <div id="bottom-nav">
    <ul>
      <li><a :href="urlLocator">Dealer Locator</a></li>
      <li><a :href="urlServices">Services</a></li>
      <li><a :href="urlDiscover">Discover</a></li>
      <li><a :href="urlApply">Apply & Contact</a></li>
      <li><a :href="urlLatestOffers">Latest Offers</a></li>
      <li><a :href="urlLogout">Logout</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NavBottom",
  data() {
    return {
      urlLocator: process.env.VUE_APP_BMWTC_URL + "/dealer-locator/",
      urlServices: process.env.VUE_APP_BMWTC_URL + "/services/",
      urlDiscover: process.env.VUE_APP_BMWTC_URL + "/discover/",
      urlApply: process.env.VUE_APP_BMWTC_URL + "/apply-contact/",
      urlLatestOffers:
        process.env.VUE_APP_BMWTC_URL + "/latest-offers#latersOffers",
      urlLogout: process.env.VUE_APP_BMWTC_URL + "/my-account"
    };
  }
};
</script>

<style scoped>
#bottom-nav {
  display: block;
  flex-basis: 100%;
  background-color: #e6e6e6;
}

#bottom-nav a {
  text-decoration: none;
  color: #262626;
  font-weight: bold;
}

#bottom-nav ul {
  list-style-type: none;
  padding: 0;
  margin-left: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
}

#bottom-nav li {
  display: inline-block;
  margin: 0 10px;
}
</style>
